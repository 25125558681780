import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LoadingGrid from "../Loading/LoadingGrid";
import PosterGrid from "../PosterGrid/PosterGrid";

import { SignedIn, SignedOut } from "../UserState/UserState";
import Seo from "../Seo";
import "./Homepage.scss";
import Announcement from "../Ads/Announcement";

function Homepage({ 
  documentary, 
  movies, 
  thriller, 
  kids, 
  series, 
  scifi, 
  horror, 
  loadingMovies, 
  loadingScifi, 
  loadingKids, 
  loadingThriller, 
  loadingHorror, 
  loadingShows, 
  loadingDocumentaries
}) {
  
  return (
  <>
  <Seo 
      title="Ínicio" 
      keywords="Filmes Grátis, Séries Grátis, Animes Grátis, Filmes 2023, Séries 2023, Como Assistir Filmes Grátis, Como Assistir Séries Grátis, Como Assistir Animes Grátis, Filmes Online Legendados Grátis, Séries Online Legendadas Grátis, Animes Online Legendados Grátis, Filmes Recentes Para Assistir, Séries Recentes Para Assistir, como assistir filmes online, onde assistir filmes grátis, séries onde assistir grátis, filmes legendados, séries legendadas, filmes completos online, séries completas online, filmes 2023 grátis, séries 2023" 
      descriptions="No DONFLIX podes assistir filmes, séries e animes grátis legendado e dublado em alta qualidade sem travar."
    />
    <div id="homepage" className="container">
    <Announcement />
      {/*<div className="home">Ínicio</div>*/}
      <section>
        <div className="main-blogs">
          <SignedIn>
          {() => (
          <>
          <div className="main-blog anim" style={{ "--delay": ".1s" }}>
            <div className="main-blog__title">
            FILMES, SÉRIES E ANIMES ONLINE.
            </div>
            <div className="main-blog__author">
              <div className="author-detail">
                <div className="author-name">Completamente grátis!</div>
              <div class="author-info">Qualidade HD<span class="seperate"></span>Legendado/Dublado</div>
            </div>
			</div>
            <div className="main-blog__time">
            	<Link className="buttongg" to="/movies/year/2023">Filmes Recentes</Link>
            </div>
          </div>
          <div className="main-blog anim" style={{ "--delay": ".2s" }}>
            <div className="main-blog__title">
             Se gostas de nós, partilha o site com os teus amigos.
            </div>
            <div className="main-blog__author">
              <div className="author-detail">
                <div className="author-name">Segue-nos no instagram! Acompanha todas as novidades.</div>
				{/* <div className="author-info">
                  Invite Code <span className="seperate"></span> Freebies<span className="seperate"></span> Rewards
                </div>*/}
              </div>
            </div>
            <div className="main-blog__time">
              <Link className="buttongg" to="/discord">
                Let me In!
              </Link>
            </div>
          </div>
          </>
          )}
          </SignedIn>
          <SignedOut>
          <div className="main-blog anim" style={{ "--delay": ".2s" }}>
            <div className="main-blog__title">
                FILMES, SÉRIES E ANIMES ONLINE.
            </div>
            <div className="main-blog__author">
              <div className="author-detail">
                <div class="main-blog__author">
                  
                <div class="auth-detail">
                  <div class="author-name">Completamente grátis!</div>
                  <div class="author-info">Qualidade HD<span class="seperate"></span>Legendado/Dublado</div>
                </div>
              </div>
              </div>
            </div>
            {/*<div className="main-blog__time">
			 <Link className="buttongg" to="/movies/year/2023">
                Filmes Recentes
              </Link>
            </div>*/}
          </div>
          <div className="main-blog anim" style={{ "--delay": ".1s" }}>
            <div className="main-blog__title">
              Se gostas de nós, partilha o site com os teus amigos.
            </div>
            <div className="main-blog__author">
              <div className="author-detail">
                <div className="author-name">Segue-nos no instagram! Acompanha todas as novidades. </div>
               {/* <div className="author-info">
                  Invite Code <span className="seperate"></span> Freebies<span className="seperate"></span> Rewards
                </div>*/}
              </div>
            </div>
             <div className="main-blog__time1">
              <div className="buttongg" to="">
               <a href="https://instagram.com/donflix_ga" target="_">@donflix_ga</a>
              </div>
            </div>
            {/*<div className="main-blog__time">
              <Link className="buttongg" to="/discord">
               Discord
              </Link>
            </div> */}
          </div>
          </SignedOut>
        </div>
      <h2>FILMES</h2>
        {loadingMovies && <LoadingGrid grid="true" />}
        {!loadingMovies && <PosterGrid movies={movies} view="grid" type="movie" /> }
      </section>
      <section>
      <h2>TOP THRILLERS</h2>
        {loadingThriller && <LoadingGrid grid="true" />}
        {!loadingThriller && <PosterGrid movies={thriller} view="grid" type="movie" /> }
        </section>
        <section>
      <h2>TOP FICÇÃO CIENTÍFICA</h2>
        {loadingScifi && <LoadingGrid grid="true" />}
        {!loadingScifi && <PosterGrid movies={scifi} view="grid" type="movie" /> }
        </section>
        <section>
      <h2>TOP KIDS</h2>
        {loadingKids && <LoadingGrid grid="true" />}
        {!loadingKids && <PosterGrid movies={kids} view="grid" type="movie" /> }
        </section>
      <section>
      <h2>TOP TERROR</h2>
        {loadingHorror && <LoadingGrid grid="true" />}
        {!loadingHorror && <PosterGrid movies={horror} view="grid" type="movie" /> }
      </section>
      <section>
        <div className="main-blogs">
          <div className="main-blog1">
            <div className="main-blog__title">Tudo que queres assistir está aqui!</div>
            <div className="main-blog__author tips">
              <div className="main-blog__time">
              
			  {/*<a className="buttongg" href="" target="_blank" rel="noreferrer">Discord</a>*/}
              
              </div>
              <div className="author-detail">
                <div className="author-name">DONFLIX conta com uma enorme base de dados de conteúdo grátis para que nunca te falte o que assistir.</div>
                {/*<div className="author-info">
                  Announcement<span className="seperate"></span>Fix & Issues<span className="seperate"></span>And More
                </div>*/}
              </div>
            </div>
          </div>
        </div>
        <h2>SÉRIES & ANIMES</h2>
        {loadingShows && <LoadingGrid grid="true" />}
        {!loadingShows && <PosterGrid movies={series} view="grid" type="tv" />}
      </section>
      <section>
      <h2>TOP DOCUMENTÁRIOS</h2>
        {loadingDocumentaries && <LoadingGrid grid="true" />}
        {!loadingDocumentaries && <PosterGrid movies={documentary} view="grid" type="tv" /> }
      </section>
      </div>
  </>
  );
}

Homepage.propTypes = {
  movies: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
  horror: PropTypes.array.isRequired,
  loadingHorror: PropTypes.bool.isRequired,
  loadingMovies: PropTypes.bool.isRequired,
  loadingShows: PropTypes.bool.isRequired,
};

export default Homepage;
