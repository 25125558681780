import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyBefr14WYxYdZ0oQ1UjyJP9Ar2oAb3ayS4",
    authDomain: "site-eb474.firebaseapp.com",
    projectId: "site-eb474",
    storageBucket: "site-eb474.appspot.com",
    messagingSenderId: "298085963545",
    appId: "1:298085963545:web:5f6b61083bfe8f8f415788",
    measurementId: "G-00KXDBF0GT"
};

const firebaseApp = firebase.initializeApp(config);

// Initialize the Firestore database
const db = firebase.firestore();
const auth = firebase.auth();

export { db, auth };
export default firebaseApp;